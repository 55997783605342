<template>
  <div class="h-screen w-full page-container px-4 pt-16">
    <div class="bg-white shadow sm:rounded-lg max-w-xl m-auto mt-10">
      <div class="px-4 py-5 space-y-6 sm:p-12">
        <img v-if="status === 'verifying'" class="animate-pulse text-center w-1/3 m-auto" src="https://media.publit.io/file/1820407_email_email-loading_emails_load-email_loading_icon.png"  />
        <img v-if="status === 'invalid'" class="text-center w-1/3 m-auto" src="https://media.publit.io/file/400598_chat_delivery-failed_failed_message_message-failed_icon.png"  />
        <img v-if="status === 'valid'" class="text-center w-1/3 m-auto" src="https://media.publit.io/file/1240425_check_email_mail_message_verification_icon.png"  />

        <h2 v-if="status === 'verifying'" class="mt-8 text-xl text-center font-extrabold text-gray-900">
          Verifying your Email Address...
        </h2>
        <h2 v-if="status === 'invalid'" class="mt-8 text-xl text-center font-extrabold text-gray-900">
          Email Verification Failed
        </h2>
        <h2 v-if="status === 'valid'" class="mt-8 text-xl text-center font-extrabold text-gray-900">
          Please return to the original browser window.
        </h2>
        <p v-if="status === 'verifying'" class="mt-2 text-sm text-gray-600 text-center">
          Please wait while we verify your email address.
        </p>
        <p v-if="status === 'invalid'" class="mt-2 text-sm text-gray-600 text-center">
          Unfortunately, it seems that the validation link is not valid or has already expired.
          Please try again to sign-up again or contact <a href="mailto:support@infusedaddons.com">support@infusedaddons.com</a> if this is unexpected.
        </p>
        <p v-if="status === 'valid'" class="mt-2 text-sm text-gray-600 text-center">
          Your email address has been confirmed. Please go back to the original browser window. You may close this tab or window.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Firebase from '../fbHelpers.js';
export default {
  data() {
      return {
        status: "verifying",
      }
  },
  async mounted() {
   const verifyToken = await Firebase.callFunction("verifyConfirmToken", {token: this.$route.params.token});
   if(verifyToken.valid) {
     if(verifyToken.redirect) {
       if(this.$router.resolve(verifyToken.redirect)) {
         this.$router.push({
           path: verifyToken.redirect,
           query: {
             userToken: verifyToken.userToken,
           }
         });
       } else {
         window.location.href = verifyToken.redirect + "?userToken=" + verifyToken.userToken;
       }
     } else {
       this.status = "valid";
     }
   } else {
     this.status = "invalid";
   }
  }
}
</script>